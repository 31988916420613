

















































































































































































































































































































































import {Values} from 'vue-i18n';
import {Component, Vue} from 'vue-property-decorator';
import {Ax} from '@/utils';
import {paramsSerializer, prepareUrl, specificityCodes, specificityToReportFormKeyMap, store} from '../common';
import I18n from '../I18n';
import {SingleOrgField} from '../organizations';
import {BudgetVariants, Comp, Dict, Org, Report, Version} from '../types';


const i18n = new I18n('modules.budget.staffing_table.old_v1_reports');


@Component({
    components: {
        'org-field': SingleOrgField,
    },
})
export default class Page extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('date', () => {
            const year = this.date.getFullYear();
            if (this.year !== year) {
                this.year = year;
            }
        });
        this.$watch('year', () => {
            store.reportYear = this.year;
            this.resetBudgetVariants();
        });
        this.$watch('selectedOrg', () => {this.resetStaffingVersion(); store.org = this.selectedOrg; });
        this.$watch('selectedGuCode', () => { this.resetAbp(); });
        this.$watch('selectedVersion', () => {store.version = this.selectedVersion; this.reload()});
        this.$watch('requiredRegionCode', () => { this.resetRegion(); });
        this.$watch('selectedRegionCode', () => { this.resetBudgetVariants(); });
        this.$watch('selectedBudgetVariant', () => { store.budgetVariant = this.selectedBudgetVariant; })
            // В запросе для получения списка отчетов selectedBudgetVariantUuid не входит в список параметров
            // this.$watch('selectedBudgetVariantUuid', () => {if (this.selectedBudgetVariantUuid !== null) {this.applyFilters();} });

        this.$watch('selectedAbpCode', () => {
            if (this.selectedAbpCode === null) {
                this.loadingBudgetPrograms = false;
                this.budgetPrograms = [];
            } else {
                this.reloadBudgetPrograms();
            }
        });
        this.$watch('selectedBudgetProgram', () => {
            if ((this.selectedBudgetProgram as unknown) === undefined) {
                this.selectedBudgetProgram = null;
            } else {
                store.budgetProgram = this.selectedBudgetProgram;
            }
        });
        this.$watch('selectedSpecificity', () => { store.specificity = this.selectedSpecificity; });
        this.$watch('reportFormKeys', () => {
            if ((this.selectedSpecificityCode !== null) && (this.selectedReportFormKey !== null) && this.reportFormKeys.notIncludes(this.selectedReportFormKey)) {
                this.selectedReportFormKey = null;
            }
        });
        this.$watch('selectedReportFormKey', (newValue: Report.FormKey | null | undefined) => {
            store.form = (newValue || null);

            if ((this.selectedReportFormKey as unknown) === undefined) {
                this.selectedReportFormKey = null;
            } else {
                this.applyFilters();
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.reloadSpecificities();
        this.resetAbp();
        this.resetRegion();
    }
    // endregion


    // region Утилиты
    private i18n = i18n;

    /** АБП - образование */
    readonly  education = '261';

    private toast(type: 'info'| 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private get dateFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    private get dateYearFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });
    }

    private get dateTimeFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    }

    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.reports.${key}`, values));
    }
    // endregion


    // region Фильтры
    private get date(): Date {
        return store.reportDate;
    }

    private get dateText(): string {
        return this.dateFormat.format(this.date);
    }

    private year = store.reportYear;

    private debug = false;


    private selectedOrg: Org | null = store.org;

    private get selectedGu(): Dict.Gu | null {
        if (this.selectedOrg?.type === 'KGKP' || this.selectedOrg?.type === 'GU') {
            return this.selectedOrg.gu;
        }
        return null;
    }

    private get selectedGuCode(): string | null { return (this.selectedGu?.code ?? null); }

    private get selectedKgkp(): Dict.GuKgkp | null {
        if (this.selectedOrg === null) return null;
        if (this.selectedOrg.type !== 'KGKP') return null;
        return this.selectedOrg.kgkp;
    }

    private get selectedGuKgkp(): Dict.Gu | null {
        return this.selectedOrg?.type === 'KGKP' ? this.selectedOrg.gu : null;
    }

    private get selectedKgkpBin(): string | null { return (this.selectedKgkp?.bin ?? null); }


    // region Выбор Версии
    private selectedVersion: Version | null = store.version;

    private loadingStaffingVersions = false;


    /**
     * Список версии штатного расписания
     *
     * @param org-code Код организации в формате Пример - “GU#4643180”
     */
    private staffingVersions: Version[] = [];

    private onStaffingVersionChanged(value: Version | null) {
        this.selectedVersion = value;
    }

    private get getVersionId (): number | null {
        return this.selectedVersion?.id ?? null
    }

    private getVersionText(report: Report): string {
        const { id = '', year = '', title = '' } = report.stVersion || {};
        return `№${id} от ${year}, н/в - ${title || ''}`;
    }

    private resetStaffingVersion() {
        this.rows = []
            this.selectedVersion = null
        if (this.selectedGuCode === null) {
            this.loadingStaffingVersions = false;
        } else {
            this.reloadStaffingVersions();
        }
    }

    private get staffingVersionsOptions(): Array<Comp.DropdownItemDef<Version>> {
        const result: Array<Comp.DropdownItemDef<Version>> = [];
        this.staffingVersions.forEach((version) => {
            const item: Comp.DropdownItemDef<Version> = { value: version, text: `Версия #${version.id}, Год ${version.year}, Н/В - ${version.title || ''}` };
            result.push(item);
        });
        return result;
    }

    private reloadStaffingVersions (): void {
        if (this.loadingStaffingVersions) {
            console.error('Cannot load staffingVersions - another loading is running');
            return;
        }

        if (this.selectedOrg === null) {
            console.error('Cannot load staffingVersions - required org-code is null');
            return;
        }

        this.loadingStaffingVersions = true;
        Ax<Version[]>(
            { url: `/api/budget/staffing_table/version/list`,
                params: {
                    'org-code': this.selectedOrg.org.code },
                  },
            data => { this.staffingVersions = data;
                },
            error => {
                this.toast('danger', 'Loading versions', `Error while loading versions - ${error}`);
                this.selectedVersion = null;
            },
            () => { this.loadingStaffingVersions = false; }
        );
    }


    private get requiredRegionCode(): string | null {
        const gu =  this.selectedGu
        if (!gu) return null;

        let result: string;
        if (gu.budgetType === '02') {
            const idRegion = gu.idRegion;
            const idRegionPart = (
                idRegion.length > 2 ?
                    idRegion.substr(0, 2)
                    :
                    idRegion
            );
            result = idRegionPart + '0101';
        } else {
            result = gu.idRegion;
        }

        return result;
    }

    private loadingRegion = false;

    private selectedRegion: Dict.BudgetRegions | null = null;

    private get selectedRegionCode(): string | null {
        const selectedRegion = this.selectedRegion;
        if (selectedRegion === null) return null;

        return selectedRegion.code;
    }

    private get selectedRegionName(): string | null {
        const selectedRegion = this.selectedRegion;
        if (selectedRegion === null) return null;

        return selectedRegion.nameRu;
    }

    private get selectedRegionText(): string | null {
        const region = this.selectedRegion;
        if (region === null) {
            return null;
        }

        let title: string;
        if (this.i18n.isKazakh) {
            title = region.nameKk || '';
        } else {
            title = region.nameRu || '';
        }

        return `${region.code} - ${title}`;
    }

    private resetRegion(): void {
        if (this.requiredRegionCode === null) {
            this.loadingRegion = false;
            this.selectedRegion = null;
        } else {
            this.reloadRegion();
        }
    }

    private reloadRegion(): void {
        if (this.loadingRegion) {
            console.error('Cannot load region - another loading is running');
            return;
        }

        const requiredRegionCode = this.requiredRegionCode;
        if (requiredRegionCode === null) {
            console.error('Cannot load region - required region code is null');
            return;
        }

        this.loadingRegion = true;
        this.selectedRegion = null;
        Ax<Dict.BudgetRegions | null>(
            { url: `/api/budget/staffing_table/budget-regions/by-code/${requiredRegionCode}` },
            data => { this.selectedRegion = data; },
            error => {
                this.toast('danger', 'Loading region', `Error while loading region - ${error}`);
                this.selectedRegion = null;
            },
            () => { this.loadingRegion = false; }
        );
    }


    private loadingBudgetVariants = false;

    private budgetVariants: BudgetVariants[] = [];

    private get isActualVariant(): boolean {
        return !(this.selectedBudgetVariant?.attribute === true && !this.selectedBudgetVariant.status);
    }

    private get budgetVariantOptions(): Array<Comp.DropdownItemDef<BudgetVariants>> {
        return this.budgetVariants.map((variant) => {
            let title: string;
            switch (this.i18n.locale.trim().toLowerCase()) {
                case 'kk':
                    title = variant.nameKk ?? variant.nameRu;
                    break;
                case 'en':
                    title = variant.nameEn ?? variant.nameRu;
                    break;
                default:
                    title = variant.nameRu;
                    break;
            }

            const statuses: Array<string> = [];
            if (variant.attribute) {
                statuses.push(this.i18n.translate('filter.actual'));
            }
            if (variant.status) {
                statuses.push(this.i18n.translate('filter.approved'));
            }

            let status: string;
            if (statuses.length > 0) {
                status = ` (${statuses.join(', ')})`;
            } else {
                status = '';
            }

            return {
                value: variant,
                text: `${title}${status}`,
            };
        })
            .sort((v1, v2) => (v1.text.localeCompare(v2.text)));
    }

    private selectedBudgetVariant: BudgetVariants | null = store.budgetVariant;

    private get selectedBudgetVariantUuid(): string | null {
        const selectedBudgetVariant = this.selectedBudgetVariant;
        if (selectedBudgetVariant === null) return null;

        return selectedBudgetVariant.variantUuid;
    }

    private resetBudgetVariants(): void {
        if (this.selectedRegionCode === null) {
            this.loadingBudgetVariants = false;
            this.budgetVariants = [];
            this.selectedBudgetVariant = null;
        } else {
            this.reloadBudgetVariants();
        }
    }

    private reloadBudgetVariants(): void {
        if (this.loadingBudgetVariants) {
            console.error('Cannot load budget variants - another loading is running');
            return;
        }

        const selectedRegionCode = this.selectedRegionCode;
        if (selectedRegionCode === null) {
            console.error('Cannot load budget variants - no selected region (code of selected region is null)');
            return;
        }

        this.loadingBudgetVariants = true;
        Ax<BudgetVariants[]>(
            { url: `/api/budget/staffing_table/budget-variants?year=${this.year}&region-code=${selectedRegionCode}` },
            data => {
                if (this.selectedBudgetVariant !== null) {
                    const uuid = this.selectedBudgetVariant.variantUuid;
                    const index = data.findIndex((testVariant) => (testVariant.variantUuid === uuid));
                    if (index >= 0) {
                        data.splice(index, 1, this.selectedBudgetVariant);
                    } else {
                        this.selectedBudgetVariant = null;
                    }
                }
                this.budgetVariants = data;
            },
            error => {
                this.toast('danger', 'Loading budget variants', `Error while loading budget variants - ${error}`);
                this.selectedAbp = null;
            },
            () => { this.loadingBudgetVariants = false; }
        );
    }

    private onBudgetVariantChanged(value: BudgetVariants | null | undefined) {
        this.selectedBudgetVariant = (value || null);
    }


    private loadingAbp = false;

    private selectedAbp: Dict.EbkFunc | null = null;

    private get selectedAbpText(): string | null {
        const abp = this.selectedAbp;
        if (abp === null) {
            return null;
        }

        let title: string;
        if (this.i18n.isKazakh) {
            title = abp.nameKk || '';
        } else {
            title = abp.nameRu || '';
        }

        return `${abp.abp} - ${title}`;
    }

    private get selectedAbpCode(): number | null {
        const abp = this.selectedAbp;
        if (abp === null) {
            return null;
        }

        return abp.abp;
    }

    private resetAbp() {
        if (this.selectedGuCode === null) {
            this.loadingAbp = false;
            this.selectedAbp = null;
            this.selectedVersion = null
        } else {
            this.reloadAbp();
            this.reloadStaffingVersions();
            this.applyFilters();
        }
    }

    private reloadAbp() {
        const selectedGuCode = this.selectedGuCode;
        if (selectedGuCode === null) {
            console.error('Cannot reload selected ABP - selected GA code is null');
            return;
        }

        const date = this.date.getTime();

        if (this.loadingAbp) {
            console.error('Cannot reload ABP - another loading is running');
            return;
        }

        this.loadingAbp = true;
        Ax<Dict.EbkFunc | null>(
            { url: `/api/budget/staffing_table/report/abp?ga-code=${selectedGuCode}&date=${date}` },
            data => { this.selectedAbp = data; },
            error => {
                this.toast('danger', 'Loading ABP', `Error while loading ABP - ${error}`);
                this.selectedAbp = null;
            },
            () => {
                this.loadingAbp = false;
                if (this.selectedBudgetProgram?.abp !== this.selectedAbp?.abp) this.selectedBudgetProgram = null;
            }
        );
    }


    private loadingBudgetPrograms = false;

    private budgetPrograms: Dict.EbkFunc[] = [];

    private get budgetProgramOptions(): Array<Comp.DropdownItemDef<Dict.EbkFunc>> {
        const result: Array<Comp.DropdownItemDef<Dict.EbkFunc>> = [];

        this.budgetPrograms.forEach(program => {
            let code = String(program.prg);
            while (code.length < 3) code = '0' + code;

            let title: string;
            if (this.i18n.isKazakh) {
                title = program.nameKk || '';
            } else {
                title = program.nameRu || '';
            }

            const item: Comp.DropdownItemDef<Dict.EbkFunc> = { value: program, text: `${code} - ${title}` };
            result.push(item);
        });

        return result;
    }

    private selectedBudgetProgram: Dict.EbkFunc | null = store.budgetProgram;

    private get selectedBudgetProgramCode(): number | null {
        const selectedBudgetProgram = this.selectedBudgetProgram;
        if (selectedBudgetProgram === null) {
            return null;
        }
        return selectedBudgetProgram.prg;
    }

    private get abpIsTarification(): boolean {
        return (this.selectedAbp?.abp || 0).toString().startsWith(this.education);
    }

    private cleanBudgetProgram() {
        this.selectedBudgetProgram = null
    }

    private reloadBudgetPrograms() {
        const selectedAbpCode = this.selectedAbpCode;
        if (selectedAbpCode === null) {
            console.error('Cannot load budget programs - ABP code is null');
            return;
        }

        if (this.loadingBudgetPrograms) {
            console.error('Cannot load budget programs - another loading is running');
            return;
        }

        this.loadingBudgetPrograms = true;
        Ax<Dict.EbkFunc[]>(
            { url: '/api/budget/staffing_table/report/budget-programs'
                    + `?abp-code=${selectedAbpCode}&date=${this.date.getTime()}` },
            data => {
                if ((this.selectedBudgetProgram !== null) && (this.selectedBudgetProgram.prg !== null)) {
                    const code = this.selectedBudgetProgram.prg;
                    const index = data.findIndex((testProgram) => (testProgram.prg === code));
                    if (index >= 0) {
                        data.splice(index, 1, this.selectedBudgetProgram);
                    } else {
                        this.selectedBudgetProgram = null;
                    }
                }
                this.budgetPrograms = data;
            },
            error => {
                this.toast('danger', 'Loading budget programs', `Error while loading budget programs - ${error}`);
                this.budgetPrograms = [];
            },
            () => { this.loadingBudgetPrograms = false; }
        );
    }


    private loadingSpecificities = false;

    private specificities: Dict.EbkEk[] = [];

    private get specificityOptions(): Array<Comp.DropdownItemDef<Dict.EbkEk>> {
        const result: Array<Comp.DropdownItemDef<Dict.EbkEk>> = [];

        const isKazakh = this.i18n.isKazakh;
        this.specificities.forEach(specificity => {
            const code = String(specificity.spf || '');

            let title: string;
            if (isKazakh) {
                title = specificity.nameKk || '';
            } else {
                title = specificity.nameRu || '';
            }

            const item: Comp.DropdownItemDef<Dict.EbkEk> = { value: specificity, text: `${code} - ${title}` };
            result.push(item);
        });

        return result;
    }

    private  funcGroupText(funcGroup: number | null): string | null {
        const funcGr = funcGroup;
        if (funcGr === null) {
            return null;
        }
        if (String(funcGr).length === 1) {
            return `0${funcGr}`
        }
        return `${funcGr}`;
    }

    private  budgetProgramText(Program: number | null): string | null {
        const budgetProgram = Program;
        if (budgetProgram === null) {
            return null;
        }
        if (String(budgetProgram).length === 1) {
            return `00${budgetProgram}`
        } else if (String(budgetProgram).length === 2) {
            return `0${budgetProgram}`}
           return `${budgetProgram}`;
    }

    private  millisToDate(date: number | null): string | null {
        if (typeof (date) === 'number'){
            return this.dateTimeFormat.format(date)
        }
        return null
    }

  private  getYear (date: string): string {
    const dateComponents = date.split('.');
    const startYear = parseInt(dateComponents[0]);
    const endYear = startYear +2
    return `${startYear} - ${endYear}`;
  }

    private selectedSpecificity: Dict.EbkEk | null = store.specificity;

    private get selectedSpecificityCode(): number | null {
        const selectedSpecificity = this.selectedSpecificity;
        if (selectedSpecificity === null) {
            return null;
        }

        return selectedSpecificity.spf;
    }

    private reloadSpecificities() {
        if (this.loadingSpecificities) {
            console.error('Cannot load specificity - another loading is running');
            return;
        }

        this.loadingSpecificities = true;
        Ax<Dict.EbkEk[]>(
            {
                url: '/api/budget/staffing_table/report/specificities',
                params: {
                    'codes': specificityCodes,
                    'date': this.date.getTime()
                },
                paramsSerializer
            },
            (data) => {
                if ((this.selectedSpecificity !== null) && (this.selectedSpecificity.spf !== null)) {
                    const testSpf = this.selectedSpecificity.spf;
                    const index = data.findIndex((testSpecificity) => (testSpecificity.spf === testSpf));
                    if (index >= 0) {
                        data.splice(index, 1, this.selectedSpecificity);
                    } else {
                        this.selectedSpecificity = null;
                    }
                }
                this.specificities = data;
            },
            (error) => {
                this.toast('danger', 'Loading specificities', `Error while loading specificities - ${error}`);
                this.specificities = [];
            },
            () => { this.loadingSpecificities = false; }
        );
    }


    private get reportFormKeys(): Report.FormKey[] {
        this.rows = []
        const selectedSpecificityCode = this.selectedSpecificityCode;
        if (selectedSpecificityCode == null) {
            return [];
        }

        const result = specificityToReportFormKeyMap.get(selectedSpecificityCode);
        // noinspection JSIncompatibleTypesComparison
        if (result === undefined) {
            return [];
        }

        return result;
    }

    private get reportFormKeyOptions(): Array<Comp.DropdownItemDef<Report.FormKey>> {
        const result: Array<Comp.DropdownItemDef<Report.FormKey>> = [];
        this.reportFormKeys.forEach(key => {
            const name = this.i18n.enumTranslate('report_form_key_name', key);
            result.push({
                text: `${this.i18n.enumTranslate('report_form_key', key)} - ${name}`,
                value: key
            });
        });
        return result;
    }

    private selectedReportFormKey: Report.FormKey | null = store.form;


    private applyFilters() {
        if (
            (this.selectedReportFormKey === null && this.selectedVersion === null)
            ||
            (
                (this.selectedGuCode === null)
                &&
                (this.selectedKgkpBin === null)
            )
        ) {
            return;
        }

        this.reload();
    }
    // endregion


    // region Отчеты
    private get fields(): Comp.TableFieldDef[] {
        const create = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
            return {
                key: `data.${dataKey}`,
                label: this.i18n.translate(`table_fields.${i18nKey}`)
            };
        };

        return [
            create('id', 'id'),
            create('reportDate', 'report_date'),
            create('planPeriod', 'plan_period'),
            create('region', 'region_code'),
            create('regionObject', 'region_name'),
            create('budgetVariantObject', 'variant_name'),
            create('staffingVersion', 'staffing_version'),
            create('abpObject', 'abp'),
            create('ga', 'ga'),
            create('funcGroup', 'functional_group'),
            create('funcSubgroup', 'functional_subgroup'),
            create('budgetProgram', 'budget_program'),
            create('budgetSubprogram', 'budget_subprogram'),
            create('specificity', 'specificity'),
            create('form', 'form'),
            create('creationMoment', 'creation_moment'),
            create('saveDateTotal', 'save_DateTotal'),
            create('debug', 'debug')
        ];
    }

    private loading = false;
    private  reportDelete: Comp.TableRow<Report>|undefined|null = null

    // noinspection JSMismatchedCollectionQueryUpdate
    private rows: Array<Comp.TableRow<Report>> = [];

    private reload() {
        const reportFormKey = this.selectedReportFormKey;
        if (reportFormKey === null) {
            console.error('Cannot load reports - selected report form key is null');
            return;
        }

        const selectedVersion = this.selectedVersion ?? null
        if (selectedVersion === null) {
            console.error('Cannot load reports - selectedVersion - id is null');
            return;
        }

        if (this.loading) {
            console.error('Cannot load rows, another loading is running');
            return;
        }

        this.loading = true;
        Ax<Report[]>(
            { url: `/api/budget/staffing_table/db/report/list`,
                params: {
                    'versionId': selectedVersion.id,
                    'reportFormKey': reportFormKey,
                },
            },
            list => {
                const rows: Array<Comp.TableRow<Report>> = [];
                list.forEach(report => {
                    const row: Comp.TableRow<Report> = {
                        id: String(report.id),
                        data: report,
                        original: report,
                        selected: false,
                        changed: false,
                        invalid: false,
                        _rowVariant: null,
                        errors: {},
                        inputValues: {}
                    };
                    rows.push(row);
                });
                this.rows = rows;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_reports'), error.toString()),
            () => {
                this.loading = false;
            }
        );
    }

    private openReport(row: Comp.TableRow<Report>) {
        const id = row.data.id;
        if (id === null) {
            console.error('Cannot open report, ID is null');
            return;
        }

        this.toast('success', 'Open report', `Report ID - ${id}`);

        if (row.data.version === 2) {
            this.$router.push({ path: `/staffing-table/report-2/${id}` });
        } else {
            this.$router.push({ path: `/staffing-table/report/${id}` });
        }
    }

    private create() {
        if (this.loading) {
            console.error('Cannot create report, another loading is running');
            return;
        }

        const isNotActualVariant = this.isActualVariant
        if (isNotActualVariant) {
            console.error('Cannot create report - budgetVariant is not actual');
            return;
        }

        const reportFormKey = this.selectedReportFormKey;
        if (typeof reportFormKey !== 'string') {
            console.error('Cannot create report - report form is not selected');
            return;
        }

        const versionId = this.selectedVersion?.id ?? null
        if (versionId === null) {
            console.error('Cannot create reports - selectedVersion - id is null');
            return;
        }

        const selectedRegionCode = this.selectedRegionCode;
        if (selectedRegionCode === null) {
            console.error('Cannot create report - no selected region code');
            return;
        }

        const selectedBudgetVariantUuid = this.selectedBudgetVariantUuid;
        if (selectedBudgetVariantUuid === null) {
            console.error('Cannot create report - no selected budget variant');
            return;
        }

        const selectedBudgetProgramCode = this.selectedBudgetProgramCode;
        if (selectedBudgetProgramCode === null) {
            console.error('Cannot create report - selected budget program code is null');
            return;
        }

        const url = prepareUrl('/api/budget/staffing_table/db/report', paramMap => {
            paramMap.set('reportFormKey', reportFormKey);
            paramMap.set('versionId', versionId.toString());
            paramMap.set('date', this.date.getTime().toString());
            paramMap.set('region-code', selectedRegionCode);
            paramMap.set('budget-variant-uuid', selectedBudgetVariantUuid);
            paramMap.set('budget-program', selectedBudgetProgramCode.toString());
            if (this.debug) paramMap.set('debug', 'true');
        });

        this.loading = true;
        let reload = false;
        Ax<Report>(
            { url, method: 'POST' },
            report => {
                const id = String(report.id);
                this.toast('success', '', this.i18n.translate('report_created', [id]));
                reload = true;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_create_report'), error.toString()),
            () => {
                this.loading = false;
                if (reload) {
                    this.hideFiltersPanel()
                    this.reload();
                }
            }
        );
    }

    // Удаляет отчет
    private removeReport(row: Comp.TableRow<Report> | undefined | null) {
        if ((row === undefined) || (row === null)) return;

        const id = row.data.id;
        const prg = this.budgetProgramText(row.data.budgetProgram)

        if (typeof id == null) {
            this.toast('danger', this.getTranslate('cannot_delete_report',), `ID - ${id}`)
            return;
        }

        this.$bvModal.msgBoxConfirm(
            `Внимание. Все подпрограммы, связанные с бюджетной программой - (${prg}), будут удалены.`,
            {
                bodyTextVariant: 'danger',
                title: `Подтвердите удаление отчета: ID - ${id}`,
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.getTranslate('delete'),
                cancelTitle: this.getTranslate('cancel')
            }
        ).then(userChoice => {
            if (userChoice) {
                if (this.loading) {
                    return;
                }
                this.loading = true;

                Ax<any>(
                    {
                        url: `/api/budget/staffing_table/delete-report/${id}`,
                        method: 'DELETE'
                    },
                    () => {
                        this.toast('success', this.getTranslate('report_removed',), `ID - ${id}`);
                    },
                    error => this.toast('danger', this.i18n.translate('error.cannot_delete_report'), `${error}`),
                    () => {
                        this.reportDelete = null
                        this.loading = false;
                        this.reload()
                    }
                );
            }
        });
    }

    onReportSelected(row: Comp.TableRow<Report>) {
        if (this.reportDelete?.id !== row.id) {
            this.reportDelete = row;
        } else {
            this.reportDelete = null;
        }
    }
    // endregion

    private hideFiltersPanel() {
        const drop: any = this.$refs.drop;
        drop.hide(true);
    }


    private openXlsx() {
        const reportFormKey = this.selectedReportFormKey;
        if (typeof reportFormKey !== 'string') {
            console.error('Cannot open XLSX - no report form selected');
            return;
        }

        const versionId = this.selectedVersion?.id ?? null
        if (versionId === null) {
            console.error('Cannot create reports - selectedVersion - id is null');
            return;
        }

        const selectedRegionCode = this.selectedRegionCode;
        if (selectedRegionCode === null) {
            console.error('Cannot open XLSX - selected region code is null');
            return;
        }

        const selectedBudgetVariantUuid = this.selectedBudgetVariantUuid;
        if (selectedBudgetVariantUuid === null) {
            console.error('Cannot open XLSX - selected budget variant UUID is null');
            return;
        }

        const selectedBudgetProgramCode = this.selectedBudgetProgramCode;
        if (selectedBudgetProgramCode === null) {
            console.error('Cannot open XLSX - selected budget program code is null');
            return;
        }

        const url = prepareUrl(`/api/budget/staffing_table/report/${versionId}/${reportFormKey}.xlsx`, paramMap => {
            paramMap.set('region-code', selectedRegionCode);
            paramMap.set('budget-variant-uuid', selectedBudgetVariantUuid);
            paramMap.set('budget-program', selectedBudgetProgramCode.toString());
            if (this.debug) paramMap.set('debug', 'true');
        });

        window.open(url, '_blank')
    }

    private openXlsxStaffingReport() {
        const versionId = this.getVersionId;
        if (versionId === null) {
            console.error('Cannot create reports - selectedVersion - id is null');
            return;
        }

        const selectedBudgetVariantUuid = this.selectedBudgetVariantUuid;
        if (selectedBudgetVariantUuid === null) {
            this.toast('info', 'Ошибка фильтра', `Выберите версию бюджета`);
            console.error('Cannot open XLSX - selected budget variant UUID is null');
            return;
        }

        const selectedBudgetProgramCode = this.selectedBudgetProgramCode ?? -1;

        const codeGu = this.selectedGuCode

        if (codeGu === null) {
            this.toast('info', 'Ошибка фильтра', `Отсутствует код ГУ`);
            console.error('Cannot open XLSX - selected codeGu is null');
            return;
        }


        const url = prepareUrl('/api/budget/staffing_table/report/employees.xlsx', paramMap => {
            paramMap.set('codeGu', codeGu);
            paramMap.set('versionId', versionId.toString());
            paramMap.set('budgetVariantUuid', selectedBudgetVariantUuid);
            paramMap.set('budgetProgram', selectedBudgetProgramCode.toString());
        });
        window.open(url, '_blank');
    }

    private openStaticXlsx() {
        const url = '/api/budget/staffing_table/report/statistic.xlsx';
        window.open(url, '_blank');
    }

    private openTariffList() {
        const versionId = this.getVersionId;
        if (versionId === null) {
            console.error('Cannot create reports - selectedVersion - id is null');
            return;
        }

        const selectedBudgetVariantUuid = this.selectedBudgetVariantUuid;
        if (selectedBudgetVariantUuid === null) {
            this.toast('info', 'Ошибка фильтра', `Выберите версию бюджета`);
            console.error('Cannot open XLSX - selected budget variant UUID is null');
            return;
        }

        const selectedBudgetProgramCode = this.selectedBudgetProgramCode ?? -1;

        const codeGu = this.selectedGuCode

        if (codeGu === null) {
            this.toast('info', 'Ошибка фильтра', `Отсутствует код ГУ`);
            console.error('Cannot open XLSX - selected codeGu is null');
            return;
        }

        const url = prepareUrl('/api/budget/staffing_table/tarification-report', paramMap => {
            paramMap.set('codeGu', codeGu);
            paramMap.set('versionId', versionId.toString());
            paramMap.set('budgetVariantUuid', selectedBudgetVariantUuid);
            paramMap.set('budgetProgram', selectedBudgetProgramCode.toString());
        });
        window.open(url, '_blank');
    }

}
